<template>
  <div>
    <titleBar
      :title="'Posting '"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800">
        <b-field horizontal label=" ">
          <header>
            <h2 class="title is-2">
              <span>Text posts 📝</span>
            </h2>
          </header>
        </b-field>
        <hr />

        <!-- START-->

        <b-field horizontal>
          <b-switch v-model="bot.enablePosting" type="is-info">
            Automate AI posts
            <b-tooltip type="is-dark" multilined label="Yup! Your chatbot will post on its own.">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <div v-if="bot.enablePosting">
          <hr />
          <b-field horizontal>
            <span>
              <h3 class="title is-4">Text Post Generators</h3>
              <p class="subtitle">
                Design prompts that will generates future posts
                <b-tooltip
                  type="is-dark"
                  multilined
                  label="You can always generate posts one by one on your page. This feature lets your automate things further by letting the AI come up with it's own posts ideas."
                >
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
              </p>
            </span>
          </b-field>

          <b-field horizontal>
            <b-button @click="addNewIdea()">Add Prompt</b-button>
          </b-field>

          <hr />
          <div v-if="true">
            <div v-for="(idea, index) in textPostIdeas" :key="index">
              <div v-if="!idea.editing" class="box" style="margin-top: 10px">
                <b-button @click="editIdea(index)" style="float: right">Edit</b-button>

                <em>{{ idea.prompt || "untitled new prompt" }}</em>
                <p style="color: #888; margin-top: 10px">{{ idea.description || "example post" }}</p>
              </div>
              <div v-else>
                <div class="box edit-idea" style="margin-top: 10px">
                  <b-button class="is-successNO" @click="saveIdea(index)" style="float: right; margin-bottom: 10px">
                    Close</b-button
                  >
                  <div class="clear"></div>

                  <b-field horizontal label="Prompt">
                    <b-input
                      type="textarea"
                      placeholder="Pick a random movie, or a random quote, or a book that is not in the top 100 famous. Convey the essence of the book/quote/movie in 5 emojis. Name the book/quote/movie, then show the emojis."
                      v-model="idea.prompt"
                      :loading="posting || idea.loading"
                      :disabled="posting || idea.loading"
                    ></b-input>
                  </b-field>

                  <b-field horizontal label="Example">
                    <b-input
                      size="is-"
                      placeholder="The movie Parasite in 5 emojis: 👨‍👩‍👧‍👦🏠💰🐞🔪"
                      v-model="idea.example"
                      :loading="posting || idea.loading"
                      :disabled="posting || idea.loading"
                    ></b-input>
                  </b-field>

                  <b-field horizontal label="Creativity">
                    <b-slider v-model="idea.creativity" :min="1" style="max-width: 200px"></b-slider>
                  </b-field>

                  <b-field horizontal label="Frequency">
                    <b-slider v-model="idea.occurencePcnt" :min="1" style="max-width: 200px"></b-slider>
                  </b-field>

                  <b-button
                    class="is-warning is-small"
                    @click="getIdeaSampleResponse(index)"
                    :loading="posting || idea.loading"
                    style="float: left; margin-bottom: 20px; margin-right: 20px"
                  >
                    TRY IT</b-button
                  >

                  <div class="message is-warning" v-if="idea.sampleResponse">
                    {{ idea.sampleResponse }}
                  </div>
                  <div class="clear"></div>
                  <!-- 
                <b-field label="Name">
                  <b-input v-model="idea.name"></b-input>
                </b-field>
                <b-field label="Description">
                  <b-input v-model="idea.description"></b-input>
                </b-field>
                 -->
                </div>
              </div>
            </div>
            <br />
          </div>
          <br />
          <br />
          <b-field horizontal label="   ">
            <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
              >Save changes</b-button
            >
          </b-field>
        </div>

        <!-- END     
    -->
        <b-field horizontal label="   ">
          <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
            >Save changes</b-button
          >
        </b-field>

        <hr />
      </div>
    </div>
  </div>
</template>

<script>
//import Editor from "@/components/e/EditorV";

export default {
  //name: "readmeEditor",
  components: {
    //Editor,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  mounted() {
    //set textPostIdeas is not defined
    if (!this.bot.textPostIdeas) {
      this.bot.textPostIdeas = [];
    }
  },
  computed: {
    textPostIdeas() {
      return this.bot.textPostIdeas || [];
    },
  },
  methods: {
    save() {
      this.$emit("save");
    },

    editIdea(index) {
      this.bot.textPostIdeas[index].editing = true;
    },
    saveIdea(index) {
      this.bot.textPostIdeas[index].editing = false;
    },
    addNewIdea() {
      var newIdea = { name: "", description: "", editing: true, occurencePcnt: 50, creativity: 70 };
      this.bot.textPostIdeas.push(newIdea);
    },

    getIdeaSampleResponse(index) {
      // var idea = this.bot.textPostIdeas[index];
      //idea.loading = true;
      //   this.bot.textPostIdeas[index].prompt = 456765;
      this.$set(this.bot.textPostIdeas[index], "loading", true);
      var prompt = this.bot.textPostIdeas[index].prompt;
      window.API.generateBotPost("d", {
        prompt: prompt, // "pick a random moovie and sumerize it in 5 emojis. name the movie, then followed by emojis. Make sure the selected movie is NOt in top100",
        creativity: this.bot.textPostIdeas[index].creativity,
        example: this.bot.textPostIdeas[index].example,
      })
        .then((text) => {
          // idea.sampleResponse = text;
          console.log(index, this.bot.textPostIdeas[index]);
          this.$set(this.bot.textPostIdeas[index], "loading", false);
          this.$set(this.bot.textPostIdeas[index], "sampleResponse", text);
          //  this.bot.textPostIdeas[index].sampleResponse = 214;
          // this.bot.textPostIdeas[index].loading = false;
          //returns a string!
        })
        .catch((e) => {
          this.$set(this.bot.textPostIdeas[index], "loading", false);
          //  this.$set(this.bot.textPostIdeas[index], "sampleResponse", "Error: " + e);
          //buefy snackbar
          this.$buefy.snackbar.open({
            message: "Problem connecting to the API. Please try again.",
            type: "is-dark",
            position: "is-bottom",
            actionText: "Close",
            delay: 5000,
          });
        });
    },
  },
};
</script>

<style></style>
